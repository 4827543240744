import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  ArrowDownTrayIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import uiConfig from '../../services/Config';
import Translate from '../Translate/Translate';
import dayjs from 'dayjs';
import UserOrder from './UserOrder';
import Loader from '../Loader/Loader';
import LocalizedLink from '../LocalizedLink';

const GET_ORDERS = gql`
  query UserOrders($page: Int) {
    userOrders(page: $page) {
      reference
      createdAt
      firstname
      lastname
      email
      phone
      transactionStatus
      orderTotal
      shippingTotal
      paymentMethod {
        id
        name
        logo
        title
        type
        countryCode
      }
      shipping {
        status
        zip
        address
        city
        country
        option
        trackingCode
        optionName
        shippingMethod {
          slug
          name
          logo
          group
          provider
        }
      }
    }
  }
`;

type UserOrders = {
  reference: string;
  createdAt: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  transactionStatus: string;
  orderTotal: number;
  shippingTotal: number;
  paymentMethod?: {
    id: string;
    name: string;
    logo: string;
    title: string;
    type: string;
    countryCode: string;
  };
  shipping: {
    status: string;
    zip: string;
    address: string;
    city: string;
    country: string;
    option: string;
    trackingCode: string;
    optionName: string;
    shippingMethod?: {
      slug: string;
      name: string;
      logo: string;
      group: string;
      provider: string;
    };
  };
};

const UserOrders = () => {
  const [page, setPage] = useState(1);
  const { loading, error, data } = useQuery<{
    userOrders: UserOrders[];
  }>(GET_ORDERS, {
    variables: { page: page },
  });

  if (loading) {
    return (
      <div className="flex justify-center m-10">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className="m-5">Error: {error.message}</div>;
  }

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const orders = data.userOrders;

  return (
    <div>
      {orders && orders.length ? (
        orders.map((order) => (
          <div
            key={order.reference}
            className="border mt-10 py-10 p-10 border-rgrey-light lg:mt-15 lg:py-15"
          >
            <div className="text-rgrey paragraph mb-6">
              <h2 className="mb-4 font-axi-bold leading-10 text-black mb-78 h4 sm:text-3xl">
                {' '}
                <Translate component="user-orders" keyword="order-nr" />:{' '}
                {order.reference}
              </h2>
              <p className="mb-2">
                <Translate component="user-orders" keyword="order-date" />:{' '}
                <span className="text-black">
                  {dayjs(order.createdAt).format('DD.MM.YYYY')}
                </span>
              </p>
              <p className="mb-2">
                <Translate component="user-orders" keyword="order-recipient" />:{' '}
                <span className="text-black">
                  {order.firstname} {order.lastname}
                </span>
              </p>
              <p className="mb-2">
                <Translate component="dashboard" keyword="telefon" />:{' '}
                <span className="text-black">
                  {order.phone.startsWith('5')
                    ? order.phone
                    : `+${order.phone}`}
                </span>
              </p>
              <p className="mb-2">
                <Translate component="checkout" keyword="delivery-method" />{' '}
                {order.shipping.shippingMethod ? (
                  <span className="text-black">
                    {order.shipping.shippingMethod.name}
                    {order.shipping.option || order.shipping.address ? (
                      <>
                        {` - `}
                        {order.shipping.optionName}
                      </>
                    ) : null}
                  </span>
                ) : null}
              </p>
              <p className="mb-2">
                <Translate
                  component="checkout"
                  keyword="payment-method-title"
                />{' '}
                <span className="text-black">{order.paymentMethod?.title}</span>
              </p>
              {/* <p className="mb-2">
                <Translate component="user-orders" keyword="order-status" />:{' '}
                <span className="text-black">
                  <Translate
                    component="order-status"
                    keyword={order.transactionStatus}
                  />
                </span>
              </p> */}
              <p className="mb-6">
                <Translate component="user-orders" keyword="total-sum" />:{' '}
                <span className="text-black font-axi-bold">
                  {order.orderTotal.toFixed(2)} €
                </span>
              </p>
              <div className="text-black block sm:flex mt-5">
                <button>
                  <a
                    className="flex items-center mr-6 md:mr-10"
                    href={`${uiConfig.api}user-orders/${order.reference}/pdf`}
                  >
                    <Translate
                      component="user-orders"
                      keyword="download-invoice"
                    />
                    <ArrowDownTrayIcon className="ml-2 w-6 h-6" />
                  </a>
                </button>
                <UserOrder
                  orderReference={order.reference}
                  shippingTotal={order.shippingTotal}
                  orderTotal={order.orderTotal}
                />
                {/* <button className="flex items-center p-1 mt-2 sm:mt-0">
                  <div className="text-left">
                    <Translate component="user-orders" keyword="return-all" />
                  </div>
                  <ChevronRightIcon className="items-center ml-2 w-6 h-6" />
                </button> */}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="my-10 mx-2">
          <p className="text-base">
            <Translate component="user-orders" keyword="no-results" />
            <LocalizedLink
              className="ml-1 underline text-rgreen"
              to={'/tooted'}
            >
              <Translate component="return-visual" keyword="xinfo-link" />
            </LocalizedLink>
          </p>
        </div>
      )}
      <div className="flex justify-center py-10 lg:py-20 px-1">
        {page > 1 ? (
          <button
            onClick={() => {
              previousPage(), scrollToTop();
            }}
            className="font-axi-bold items-center bg-white border rounded inline-flex transition-all duration-100 p-4 border-solid border-rgrey-light hover:text-white hover:bg-rgreen hover:transition-all hover:duration-100 mx-2"
          >
            <ChevronLeftIcon className="mr-2 h-5 w-5" />
            <Translate component="user-orders" keyword="newer-orders" />
          </button>
        ) : null}
        {orders.length < 10 ? null : (
          <button
            onClick={() => {
              nextPage(), scrollToTop();
            }}
            className="font-axi-bold items-center bg-white border rounded inline-flex transition-all duration-100 p-4 border-solid border-rgrey-light hover:text-white hover:bg-rgreen hover:transition-all hover:duration-100 mx-2"
          >
            <Translate component="user-orders" keyword="older-orders" />
            <ChevronRightIcon className="ml-2 h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
};

export default UserOrders;

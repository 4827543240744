import { action, makeObservable, observable, when } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import AuthenticationStore from '../../stores/Authentication';
import BannersStore, { BannerComponent } from '../../stores/Banners';
import CartStore from '../../stores/Cart';
import UserStore from '../../stores/User';
import Alert, { Type } from '../Alert';
import UserOrders from '../UserOrders/UserOrders';
// import Latest from '../Product/Latest';
import ReturnPage from '../Content/ReturnPage';
import Translate from '../Translate/Translate';
import PhoneNumberInput from './PhoneNumberInput';
import './styles/index.scss';

interface Props {
  navigate: NavigateFunction;
  authentication?: AuthenticationStore;
  banners?: BannersStore;
  user?: UserStore;
  cart?: CartStore;
}

@inject('authentication', 'user', 'cart', 'banners')
@observer
class Dashboard extends React.Component<Props> {
  @observable bannerImages = [];
  @observable firstname = '';
  @observable lastname = '';
  @observable email = '';
  @observable phone = '';
  @observable successMessage = '';
  @observable toggleDropdown = false;
  @observable panelOption = 'userOrders';
  @observable panelTitle = 1;
  @observable dashboardTitle = (
    <Translate component="dashboard" keyword="ajalugu" />
  );

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.tryToUpdate = this.tryToUpdate.bind(this);
    this.setDropdown = this.setDropdown.bind(this);
  }

  componentDidMount() {
    const { authentication } = this.props;
    when(
      () => authentication.initialized,
      () => this.check(),
    );

    this.loadBanners();
  }

  async loadBanners() {
    const { banners } = this.props;
    const bannerImages = await banners.loadByComponent(BannerComponent.RETURNS);
    this.bannerImages = bannerImages;
  }

  async check() {
    const { user, authentication, navigate } = this.props;

    if (!authentication.loggedIn) {
      navigate('/');
    }

    when(
      () => user.loaded,
      () => this.filluserInfo(),
    );
  }

  async tryToUpdate() {
    const { user } = this.props;
    user.update(this.firstname, this.lastname, this.email, this.phone);
    this.setMessage('data-update');
    setTimeout(() => {
      this.setMessage('');
    }, 5000);
  }

  async handleLogOut() {
    const { authentication, cart, navigate } = this.props;
    await authentication.logout();
    await cart.create();

    navigate('/');
  }

  @action filluserInfo() {
    const { user } = this.props;

    let userPhone = user.phone;
    if (userPhone.startsWith('5')) {
      userPhone = `372${userPhone}`;
    } else if (userPhone.startsWith('+')) {
      userPhone = userPhone.substring(1);
    }

    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.email = user.email;
    this.phone = userPhone;
  }

  handleValueChange(event) {
    this.setValue(event.target.name, event.target.value);
  }

  @action setDropdown() {
    this.toggleDropdown = !this.toggleDropdown;
  }

  @action setMessage(message) {
    this.successMessage = message;
  }

  @action setPanelOption(id: number, option: string, title: string) {
    this.panelTitle = id;
    this.panelOption = option;
    this.dashboardTitle = <>{title}</>;
    this.setDropdown();
  }

  dropDownOptions() {
    const options = [
      {
        id: 3,
        title: <Translate component="dashboard" keyword="ajalugu" />,
        value: 'userOrders',
      },
      {
        id: 4,
        title: <Translate component="dashboard" keyword="tagastus" />,
        value: 'return',
      },
      {
        id: 2,
        title: <Translate component="dashboard" keyword="andmed" />,
        value: 'information',
      },
      // {
      //   id: 1,
      //   title: 'Minu konto',
      //   // title: <Translate component="dashboard" keyword="andmed" />,
      //   value: 'account',
      // },
    ];

    const dropItem = options.map((item) => {
      let activeClass = '';
      const onClick = this.setPanelOption.bind(
        this,
        item.id,
        item.value,
        item.title,
      );
      if (this.panelTitle === item.id) {
        activeClass = ' active';
      }

      return (
        <li
          key={item.id}
          onClick={onClick}
          className={`dashboard__dropdown__item${activeClass}`}
        >
          {item.title}
        </li>
      );
    });

    return (
      <ul className={`dashboard__dropdown ${!this.toggleDropdown && 'hidden'}`}>
        {dropItem}
      </ul>
    );
  }

  @action setValue(name: string, value: string | number) {
    this[name] = value;
  }

  render() {
    const { authentication } = this.props;

    if (!authentication.loggedIn) {
      return null;
    }

    return (
      <div className="dashboard">
        <h2 className="dashboard__title">
          {/* <Translate component="dashboard" keyword="konto" /> */}
          {this.dashboardTitle}
        </h2>
        <div className="dashboard__menu">
          <div className="dashboard__menu__container">
            <ul className="dashboard__menu__list">
              <li
                onClick={this.setDropdown}
                className={`dashboard__menu__item ${
                  this.toggleDropdown ? 'open' : null
                }`}
              >
                {this.dashboardTitle}
              </li>
              <li onClick={this.handleLogOut} className="dashboard__menu__item">
                <Translate component="dashboard" keyword="logout" />
              </li>
            </ul>
            <div className="lg:hidden">
              {this.toggleDropdown ? this.dropDownOptions() : null}
            </div>
            <div className="hidden lg:block lg:w-11/12">
              {this.dropDownOptions()}
            </div>
          </div>

          {/* <div className="dashboard__latest">
                            <div className="image-blocks">
                                <div className="wrap">{this.imageBlocks()}</div>
                            </div>
                            <Latest productSlug="dashboard" title="Sinu viimati vaadatud tooted" />
                        </div>
                     */}

          {this.panelOption === 'information' ? (
            <div className="dashboard__information">
              <div className="left">
                <h3>
                  <Translate component="dashboard" keyword="kontakt-andmed" />
                </h3>
                <p>
                  <Translate component="dashboard" keyword="kontakt-text" />
                </p>
              </div>
              <div className="right">
                <div className="form">
                  <div className="field">
                    <label htmlFor="firstname">
                      <Translate component="dashboard" keyword="eesnimi" />
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      onChange={this.handleValueChange}
                      value={this.firstname}
                    />
                  </div>

                  <div className="field">
                    <label htmlFor="lastname">
                      <Translate component="dashboard" keyword="perenimi" />
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      onChange={this.handleValueChange}
                      value={this.lastname}
                    />
                  </div>

                  <div className="field">
                    <label htmlFor="loginEmail">
                      <Translate component="dashboard" keyword="post" />
                    </label>
                    <input
                      type="text"
                      name="loginEmail"
                      id="loginEmail"
                      onChange={this.handleValueChange}
                      value={this.email}
                      disabled={true}
                    />
                  </div>

                  <div className="field">
                    <label htmlFor="phone">
                      <Translate component="dashboard" keyword="telefon" />
                    </label>
                    <PhoneNumberInput
                      inputClassName="!pt-2.5 !font-axi-book"
                      value={this.phone}
                      onChange={(newValue: string) => {
                        this.setValue('phone', newValue);
                      }}
                    />
                  </div>
                  <Alert show={!!this.successMessage} type={Type.SUCCESS}>
                    <Translate
                      component="checkout"
                      keyword={this.successMessage}
                    />
                  </Alert>
                  <div className="save" onClick={this.tryToUpdate}>
                    <Translate component="dashboard" keyword="salvesta" />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {this.panelOption === 'return' ? (
            <div className="dashboard__return">
              <ReturnPage />
            </div>
          ) : null}
          {this.panelOption === 'userOrders' ? (
            <div className="">
              <UserOrders />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Dashboard;

import React from 'react';
import { Fragment, useState } from 'react';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import UserOrderItems from './UserOrderItems';
import Translate from '../Translate/Translate';

type Props = {
  orderReference?: string;
  shippingTotal?: number;
  orderTotal?: number;
};

export default function UserOrder({
  orderReference,
  shippingTotal,
  orderTotal,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="">
        <button
          type="button"
          onClick={openModal}
          className="flex items-center mt-3 sm:mt-0"
        >
          <div className="text-left">
            <Translate component="user-orders" keyword="view-order" />
          </div>
          <ChevronRightIcon className="items-center ml-2 w-6 h-6" />
        </button>
      </div>
      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center items-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 w-full max-w-7xl p-6">
                  <div className="absolute right-0 top-0 pr-4 pt-4 block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-black outline-none"
                      onClick={() => setIsOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6 m-2" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:items-start">
                    <div className="mt-5 sm:ml-4 sm:mt-0 text-left">
                      <DialogTitle
                        as="h2"
                        className="my-4 text-2xl font-semibold leading-8 text-black"
                      >
                        <Translate component="user-orders" keyword="order-nr" />
                        : {orderReference}
                      </DialogTitle>
                      <div className="border-b border-rgray my-4"></div>
                      <div className="mt-2">
                        <p className="text-sm text-rgrey"></p>
                        <UserOrderItems
                          orderReference={orderReference}
                          shippingTotal={shippingTotal}
                          orderTotal={orderTotal}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="m-5 sm:mt-4 sm:flex sm:flex-row">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-rgreen px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rgreen-dark sm:mr-3 sm:w-auto"
                      onClick={() => setIsOpen(false)}
                    >
                      Sulge
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-rgrey hover:bg-rgrey-light sm:mt-0 sm:w-auto"
                      onClick={() => setIsOpen(false)}
                    >
                      Tagasta
                    </button>
                  </div> */}
                  <div className="sm:ml-4 border-b border-rgray my-4"></div>
                  <div className="sm:ml-4 sm:mt-0 text-right justify-end text-base block sm:flex">
                    <p className="mb-2 sm:mx-4">
                      <Translate component="product" keyword="transport" />:{' '}
                      <span className="text-black">
                        {shippingTotal.toFixed(2)} €
                      </span>
                    </p>
                    <p className="mb-6 sm:mx-4">
                      <Translate component="user-orders" keyword="total-sum" />:{' '}
                      <span className="text-black font-axi-bold">
                        {orderTotal.toFixed(2)} €
                      </span>
                    </p>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

import React from 'react';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';
import 'react-phone-input-2/lib/bootstrap.css';

type Props = {
  inputClassName: string;
  value: string;
  onChange: (e) => void;
  onBlur?: (e) => void;
};

const PhoneNumberInput = ({
  inputClassName,
  value,
  onChange,
  onBlur,
}: Props) => {
  return (
    <PhoneInput
      country="ee"
      onlyCountries={['ee', 'lv', 'lt']}
      value={value}
      onChange={onChange}
      onBlur={onBlur && typeof onBlur === 'function' ? onBlur : undefined}
      placeholder=""
      countryCodeEditable={false}
      inputProps={{
        name: 'phone',
        required: true,
      }}
      inputClass={classNames(
        '!w-full !relative !border !rounded !text-base !items-center !pr-2.5 !pl-16 !pb-2.5 !max-h-14 !text-black !shadow-none placeholder:!text-rgrey',
        inputClassName,
      )}
    />
  );
};

export default PhoneNumberInput;

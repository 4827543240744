import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Translate from '../Translate/Translate';
import LocalizedLink from '../LocalizedLink';
import Image from '../Image';
import { Sizes } from '../../constants/Image';
import Loader from '../Loader/Loader';

const GET_ORDERITEMS = gql`
  query UserOrderItems($orderReference: String) {
    userOrderItems(orderReference: $orderReference) {
      id
      finalPrice {
        type
        value
      }
      originalPrice {
        type
        value
      }
      product {
        id
        title
        slug
        productCode
        brand {
          id
          title
        }
        colours {
          id
          title
        }
        media {
          cdn_url
          urls {
            W100
            W800
          }
        }
      }
      variation {
        id
        type
        value
        code
      }
    }
  }
`;

type UserOrderItems = {
  id: string;
  finalPrice: {
    type: string;
    value: number;
  };
  originalPrice: {
    type: string;
    value: number;
  };
  product: {
    id: number;
    title: string;
    slug: string;
    productCode: string;
    brand: {
      id: number;
      title: string;
    };
    colours: {
      id: string;
      title: string;
    }[];
    media: {
      cdn_url: string;
      urls: {
        W100: string;
        W800: string;
      };
    }[];
  };
  variation: {
    id: number;
    type: string;
    value: string;
    code: string;
  };
};

type Props = {
  orderReference?: string;
  shippingTotal?: number;
  orderTotal?: number;
};

const UserOrderItems = ({
  orderReference,
  shippingTotal,
  orderTotal,
}: Props) => {
  const { loading, error, data } = useQuery<{
    userOrderItems: UserOrderItems[];
  }>(GET_ORDERITEMS, {
    variables: { orderReference, shippingTotal, orderTotal },
  });

  if (loading) {
    return (
      <div className="flex justify-center m-10">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className="m-5">Error: {error.message}</div>;
  }

  const items = data.userOrderItems;

  return (
    <div>
      <div className="grid gap-8 pt-2 w-full grid-cols-1 lg:grid-cols-2">
        {items.map((item) => {
          const coverImage = item.product.media.length
            ? item.product.media[0]
            : null;
          return (
            <div key={orderReference} className={'flex'}>
              <LocalizedLink
                to={`/toode/${item.product.slug}`}
                className="mr-4 md:mr-8"
              >
                <Image
                  className={
                    'rounded bg-rgrey-lightlight object-cover max-w-32 md:max-w-52 min-w-32 h-auto md:h-full w-auto'
                  }
                  image={coverImage}
                  defaultSize={Sizes.W800}
                  alt={`${item.product.brand?.title} ${item.product.title}`}
                  width="100"
                  height="100"
                />
              </LocalizedLink>
              <div className="flex flex-col justify-between">
                <div className="mt-0">
                  <div className="font-axi-bold">
                    <p className="paragraph mb-2">{item.product.title}</p>
                    {item.finalPrice ? (
                      <p className="mb-3 paragraph text-rgreen">
                        {item.finalPrice.value.toFixed(2)} €
                        <span className="line-through ml-2 text-black text-s">
                          {item.originalPrice.value.toFixed(2)} €
                        </span>
                      </p>
                    ) : (
                      <p className="mb-3 paragraph text-black">
                        {item.originalPrice.value.toFixed(2)} €
                      </p>
                    )}
                  </div>
                  {item.product.brand?.title ? (
                    <p className="label mb-2">
                      <span className="text-rgrey">
                        <Translate component="products" keyword="brand" />:{' '}
                      </span>
                      {item.product.brand?.title}
                    </p>
                  ) : null}
                  {item.variation && (
                    <p className="label mb-2">
                      <span className="text-rgrey">
                        <Translate component="products" keyword="size" />:
                      </span>{' '}
                      {item.variation.value}
                    </p>
                  )}
                  <p className="label mb-2">
                    <span className="text-rgrey">
                      <Translate component="product" keyword="code-tooltip" />:
                    </span>{' '}
                    {item.variation.code}
                  </p>
                  {item.product.colours.length > 0 ? (
                    <p className="label">
                      <span className="text-rgrey">
                        <Translate component="products" keyword="colour" />:
                      </span>{' '}
                      {item.product.colours[0].title}
                    </p>
                  ) : null}
                </div>
                {/* <div className="font-axi-bold paragraph mt-5 md:mt-0">
                  <button className="border border-rgrey-light rounded  hover:text-white hover:bg-rgreen hover:border-rgreen py-4 px-4 sm:py-5 sm:px-5">
                    <Translate
                      component="user-orders"
                      keyword="return-exchange"
                    />
                  </button>
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserOrderItems;
